<template>
  <div id="detail-invalid-date-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <div>
          <!-- title -->
          <b-card-title class="d-flex align-items-center">
            <i class="bi bi-info-circle"></i>
            <span style="margin-left: 5px">
              Detail Berita pada <strong>{{ filter_data.portal }}</strong>
            </span>
          </b-card-title>
        </div>
        <!-- filter component -->
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <!-- back button -->
          <b-button variant="outline-info" @click="goBack()">
            <feather-icon icon="ArrowLeftCircleIcon" /> Kembali
          </b-button>
          <!-- back button -->
          <b-button variant="outline-success" @click="exportData()">
            <feather-icon icon="FileTextIcon" /> Export
          </b-button>
        </div>
      </b-card-header>
      <div class="mt-1">
        <!-- loader component -->
        <loader-component v-if="is_loading" />
        <!-- table component -->
        <table-component v-else :data="table_data" :is_pagination="false" />
        <paginationInfo
          :page="pagination.page"
          :items="pagination.items"
          :count="pagination.count"
          @update-value="paginationChanged"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
import paginationInfo from "@/views/components/paginationInfo.vue";

export default {
  name: "InvalidDateDetail",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    paginationInfo,
    "table-component": dataTable,
    "loader-component": loader,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        portal: this.$route.query.portal,
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "account",
            label: "nama akun",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "date",
            label: "tanggal",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "7%" },
          },
          {
            key: "timestamp",
            label: "time stamp",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "url",
            label: "url",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
        ],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    exportData() {
      let api =
        process.env.VUE_APP_API_URL +
        "scraper-log/invalid-date/export?account=" +
        this.filter_data.portal;
      window.open(api);
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        account: this.filter_data.portal,
        page: this.pagination.page,
        items: this.pagination.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL +
        "scraper-log/invalid-date/detail?" +
        query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res?.data?.media_date || [];
          this.pagination.count = res?.data?.page_info[0]?.total || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
